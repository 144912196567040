import { onMounted, defineComponent, reactive, toRefs } from 'vue';
import { getWallet, doWithdraw } from '@/api/wallet';
import { useRouter } from 'vue-router';
import { Toast } from 'vant';
import 'vant/es/toast/style';
export default defineComponent({
  name: 'Wallet',

  setup() {
    const router = useRouter();
    const state = reactive({
      isComplete: false,
      data: {
        balance: '--',
        cs_tel: null,
        show_trans: false
      }
    });
    /**
     * 提现
     */

    const onWithdraws = async () => {
      const toast = Toast.loading({
        message: '加载中...',
        forbidClick: true
      });
      const {
        msg,
        status
      } = await doWithdraw();
      toast.clear();

      if (status != 1) {
        return Toast(msg);
      }

      onTransDetail();
    };
    /**
     * 交易明细
     */


    const onTransDetail = () => {
      router.push({
        name: 'TransDetail'
      });
    };

    const fetchData = async () => {
      const toast = Toast.loading({
        message: '加载中...',
        forbidClick: true
      });
      const {
        data
      } = await getWallet();
      toast.clear();
      state.data = data;
      state.isComplete = true;
    };

    const onCallCS = () => {
      const a = document.createElement('a');
      a.href = 'tel:' + state.data.cs_tel;
      a.click();
    };

    onMounted(() => {
      fetchData();
    });
    return { ...toRefs(state),
      onTransDetail,
      onWithdraws,
      onCallCS
    };
  }

});