import request from '@/utils/request'

/**
 * 获取我的钱包
 */
export function getWallet() {
  return request({
    url: '/wallet/wallet',
    method: 'get',
  })
}

/**
 * 提现
 */
export function doWithdraw() {
  return request({
    url: '/wallet/withdraw',
    method: 'post',
  })
}

/**
 * 获取交易记录
 */
export function getTransDetail() {
  return request({
    url: '/wallet/transDetail',
    method: 'get',
  })
}
